import React from 'react';
import { Snowflakes } from '../Snowflake';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <div className={'main'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Joseph Chilliams</title>
        <meta name='description' content='Official site for Chicago rapper Joseph Chilliams.'/>
        </Helmet>
      <div className={'border-img'}/>
        <div className='menu-background menu-button'>
          <Link to={'/menu/'} >MENU</Link>
        </div>
      <Snowflakes/>
   </div>
  )
}
